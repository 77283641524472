<template>
  <v-container fluid
    ><v-row
      ><v-col
        ><v-select
          label="Devices"
          :items="devices"
          item-title="deviceIdentity"
          item-value="id"
          variant="outlined"
          density="compact"
          v-model="selectedDevice"
          @update:model-value="getLog()" /></v-col
    ></v-row>
    <v-row>
      <v-col
        ><v-spacer /><v-btn @click="paginaAnterior()">Página Anterior</v-btn
        ><v-btn @click="proximaPagina()">Proxima Página</v-btn
        ><v-btn @click="refresh()"
          ><v-icon>fas fa-refresh</v-icon></v-btn
        ></v-col
      >
    </v-row>
    <v-row>
      <v-col
        ><v-data-table
          :items="log"
          item-key="name"
          :headers="headers"
          hide-default-footer
        >
          <template v-slot:[`item.history`]="{ item }">
            <code>
              <pre>{{ item.history.data }}</pre>
            </code>
          </template>
          <template v-slot:[`item.history.data.image`]="{ item }">
            <v-img
              :src="`data:image/jpg;base64, ${
                item.history.data ? item.history.data.image : ''
              }`"
              :width="300"
            />
          </template> </v-data-table
      ></v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
const headers = [
  { title: "Criado em", key: "createdAt", value: "createdAt" },
  { title: "Device", key: "history.device_id", value: "history.device_id" },
  { title: "Imagem", key: "history.data.image", value: "history.data.image" },
  { title: "Histórico", key: "history", value: "history" },
];
export default defineComponent({
  name: "HomeView",
  data: () => ({
    devices: [],
    selectedDevice: null,
    log: [],
    headers,
    skip: 0,
    take: 10,
  }),
  methods: {
    getDevices() {
      axios
        .get("https://api.iot.arxexperience.com.br/dashboard/devices/10/0", {
          headers: { "Content-Type": "application/json" },
        })
        .then((response: any) => {
          this.$data.devices = response.data.devices;
        });
    },
    getLog() {
      console.log("teste", this.$data.selectedDevice);
      axios
        .get(
          `https://api.iot.arxexperience.com.br/dashboard/log/${this.$data.selectedDevice}/${this.$data.take}/${this.$data.skip}`,
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((response: any) => {
          this.$data.log = response.data.log;
        })
        .catch((error) => console.error(error));
    },
    refresh() {
      this.$data.take = 10;
      this.$data.skip = 0;
      this.getLog();
    },
    proximaPagina() {
      this.$data.skip = this.$data.skip + this.$data.take;
      this.getLog();
    },
    paginaAnterior() {
      this.$data.skip = this.$data.skip - this.$data.take;
      if (this.$data.skip < 0) this.$data.skip = 0;
      this.getLog();
    },
  },
  mounted() {
    this.getDevices();
  },
});
</script>
