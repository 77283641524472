import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_footer = _resolveComponent("v-footer")!
  const _component_v_layout = _resolveComponent("v-layout")!

  return (_openBlock(), _createBlock(_component_v_layout, {
    class: "rounded rounded-md",
    fluid: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar, { class: "bg-indigo-lighten-1" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_btn, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: "fas fa-home",
                color: "blue-grey-lighten-5"
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            class: "btn",
            color: "blue-grey-lighten-5"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Devices")
            ])),
            _: 1
          }),
          _createVNode(_component_v_spacer)
        ]),
        _: 1
      }),
      (_ctx.$store.loggedIn)
        ? (_openBlock(), _createBlock(_component_v_navigation_drawer, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item, { title: "Navigation drawer" })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_main, {
        class: "d-flex align-center justify-center",
        style: {"min-height":"300px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }),
      _createVNode(_component_v_footer, {
        app: "",
        class: "bg-indigo-lighten-1"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_spacer),
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-lime-accent-1" }, "ARX Experience® 2024", -1))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}