import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { fluid: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                label: "Devices",
                items: _ctx.devices,
                "item-title": "deviceIdentity",
                "item-value": "id",
                variant: "outlined",
                density: "compact",
                modelValue: _ctx.selectedDevice,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDevice) = $event)),
                  _cache[1] || (_cache[1] = ($event: any) => (_ctx.getLog()))
                ]
              }, null, 8, ["items", "modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.paginaAnterior()))
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("Página Anterior")
                ])),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.proximaPagina()))
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Proxima Página")
                ])),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.refresh()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("fas fa-refresh")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_data_table, {
                items: _ctx.log,
                "item-key": "name",
                headers: _ctx.headers,
                "hide-default-footer": ""
              }, {
                [`item.history`]: _withCtx(({ item }) => [
                  _createElementVNode("code", null, [
                    _createElementVNode("pre", null, _toDisplayString(item.history.data), 1)
                  ])
                ]),
                [`item.history.data.image`]: _withCtx(({ item }) => [
                  _createVNode(_component_v_img, {
                    src: `data:image/jpg;base64, ${
                item.history.data ? item.history.data.image : ''
              }`,
                    width: 300
                  }, null, 8, ["src"])
                ]),
                _: 2
              }, 1032, ["items", "headers"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}