<template>
  <v-layout class="rounded rounded-md" fluid>
    <v-app-bar class="bg-indigo-lighten-1"
      ><v-spacer /><v-btn
        ><v-icon icon="fas fa-home" color="blue-grey-lighten-5"
      /></v-btn>
      <v-btn class="btn" color="blue-grey-lighten-5">Devices</v-btn>
      <v-spacer
    /></v-app-bar>
    <v-navigation-drawer v-if="$store.loggedIn">
      <v-list>
        <v-list-item title="Navigation drawer"></v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main
      class="d-flex align-center justify-center"
      style="min-height: 300px"
    >
      <router-view />
    </v-main>
    <v-footer app class="bg-indigo-lighten-1"
      ><v-spacer /><span class="text-lime-accent-1"
        >ARX Experience&reg; 2024</span
      ></v-footer
    >
  </v-layout>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",

  data() {
    return {
      //
    };
  },
});
</script>
